import React, { useState, useEffect } from 'react';
import './Table.css';

const initialRow = {
  entities: '',
  curved_entities: '',
  details: '',
  unique_details: '',
  curved_details: '',
  adjacencies: '',
  design: true,
  production: false,
  construction: false,
  development: false,
  duration: '',
  cost: ''
};

const Table = () => {
  const [rows, setRows] = useState(() => {
    const savedRows = localStorage.getItem('rows');
    return savedRows ? JSON.parse(savedRows) : [initialRow];
  });
  const [loadingRows, setLoadingRows] = useState([]);

  useEffect(() => {
    localStorage.setItem('rows', JSON.stringify(rows));
  }, [rows]);

  const handleChange = (index, name, value) => {
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);

    // Call handleBlur for checkboxes immediately after change
    if (typeof value === 'boolean') {
      handleBlur(index);
    }
  };

  const handleBlur = (index) => {
    if (Object.values(rows[index]).slice(0, 10).every(val => val !== '')) {
      setLoadingRows([...loadingRows, index]);

      fetch('/api/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(rows[index])
      })
        .then(response => response.json())
        .then(data => {
          const newRows = [...rows];
          newRows[index] = { ...rows[index], ...data };
          setRows(newRows);
          setLoadingRows(loadingRows.filter(i => i !== index));
        });
    }
  };

  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1];
    const newRow = { ...lastRow};
    setRows([...rows, newRow]);
    
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Entities</th>
            <th>Curved Entities</th>
            <th>Details</th>
            <th>Unique Details</th>
            <th>Curved Details</th>
            <th>Adjacencies</th>
            <th>Design</th>
            <th>Production</th>
            <th>Construction</th>
            <th>Development</th>
            <th className="fixed-width">Duration</th>
            <th className="fixed-width">Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {Object.keys(initialRow).map((key, idx) => (
                idx < 10 ? (
                  <td key={key}>
                    {typeof row[key] === 'boolean' ? (
                      <input
                        type="checkbox"
                        checked={row[key]}
                        onChange={(e) => handleChange(index, key, e.target.checked)}
                      />
                    ) : (
                      <input
                        type="text"
                        value={row[key]}
                        onChange={(e) => handleChange(index, key, e.target.value)}
                        onBlur={() => handleBlur(index)}
                      />
                    )}
                  </td>
                ) : (
                  <td key={key} className="fixed-width">
                    {loadingRows.includes(index) ? 'Loading...' : row[key]}
                  </td>
                )
              ))}
              <td>
                <button onClick={() => handleRemoveRow(index)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>Add Row</button>
    </div>
  );
};

export default Table;